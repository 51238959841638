// import SlimSelect from "slim-select";

(function () {
  const closeButton = document.querySelector(".close-button");

  if (closeButton) {
    const openCloseButton = document.querySelector(".open-close-button");

    closeButton.addEventListener("click", () => {
      const applyOpenContainer = document.querySelector(
        ".apply-open-container",
      );
      const applyClosedContainer = document.querySelector(
        ".apply-closed-container",
      );
      applyOpenContainer.style.display = "none";
      applyClosedContainer.style.display = "block";
    });

    const openFunction = () => {
      const applyOpenContainer = document.querySelector(
        ".apply-open-container",
      );
      const applyClosedContainer = document.querySelector(
        ".apply-closed-container",
      );

      const email = applyClosedContainer.querySelector(
        'input[name="email"]',
      ).value;
      applyOpenContainer.querySelector('input[name="email"]').value = email;

      applyOpenContainer.style.display = "block";
      applyClosedContainer.style.display = "none";
    };

    document
      .querySelector(".apply-closed-container .future-block")
      .addEventListener("click", openFunction);
    openCloseButton.addEventListener("click", openFunction);
  }

  const downloadPDF = (data, fileName) => {
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const forms = document.querySelectorAll("form.apply");
  const sectorSelectElement = document.querySelector(
    "form.apply #sector-select",
  );
  // if (forms.length > 0 && sectorSelectElement) {
  //   new SlimSelect({ select: "#sector-select", showSearch: false });
  // }
  if (forms.length > 0) {
    forms.forEach((form) => {
      form.addEventListener("submit", async (e) => {
        e.preventDefault();
        const submitButton = form.querySelector(".button");
        try {
          submitButton.disabled = true;
          form.querySelector(".form-messages").innerHTML = "";
          form.querySelector(".form-messages").classList.remove("error");
          form.querySelector(".form-messages").classList.remove("success");

          const action = form.action;
          const formData = new FormData(form);
          console.log(formData);
          const data = {};
          formData.forEach((value, key) => (data[key] = value));
          console.log(data);
          const response = await fetch(action, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify(data),
          });

          //     // const blob = await response.blob()
          const res = await response.json();
          console.log(res);

          //     if (res.file) {
          //       downloadPDF(res.file, res.filename);
          //     }

          if (res.message) {
            form.querySelector(".form-messages").innerHTML = res.message;
            form.querySelector(".form-messages").classList.add("success");
            form.querySelector(".form-messages").scrollIntoView();
          }
          form.reset();
          submitButton.disabled = false;
        } catch (e) {
          console.error(e);
          form.querySelector(".form-messages").innerHTML =
            "Error submitting the request, please try again later!";
          form.querySelector(".form-messages").classList.add("error");
          form.querySelector(".form-messages").scrollIntoView();
          submitButton.disabled = false;
        }
      });
    });
  }
})();
